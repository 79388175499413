import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, retryWhen, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import * as WidgetActions from './widget.actions';
import { FreeSlotsResponseDTO, OnlineAppointmentService } from '@noventi/gp-platform/online-appointments';
import { PersonService, PublicPersonDTO, PublicTreatmentDTO } from '@noventi/gp-platform/care-providers';

@Injectable()
export class WidgetEffects {
	provider$ = createEffect(() => this.actions$.pipe(
		ofType(WidgetActions.Provider),
		switchMap(({payload}) =>
			this.personServiceApi.readPublicPersonByToken(payload).pipe(
				switchMap((result: PublicPersonDTO) => of(WidgetActions.ProviderCompleted({payload: result}))),
				catchError((error) => of(WidgetActions.ProviderFailed({error})))
			)
		))
	);

	treatment$ = createEffect(() => this.actions$.pipe(
		ofType(WidgetActions.Treatments),
		switchMap(({payload}) =>
			this.personServiceApi.getPublicTreatementsByWidgetToken(payload).pipe(
				switchMap((result: Array<PublicTreatmentDTO>) => of(WidgetActions.TreatmentsCompleted({payload: result}))),
				catchError((error) => of(WidgetActions.TreatmentsFailed({error})))
			)
		))
	);

	slots$ = createEffect(() => this.actions$.pipe(
		ofType(WidgetActions.Slots),
		switchMap(({payload: {freeSlotsRequest, findFirstAvailable}}) =>
			this.appointmentServiceApi.readFreeSlots(freeSlotsRequest, findFirstAvailable).pipe(
				switchMap((result: FreeSlotsResponseDTO) => of(WidgetActions.SlotsCompleted({payload: result}))),
				retryWhen(() => EMPTY),
				catchError((error) => of(WidgetActions.SlotsFailed({error})))
			)
		))
	);

	constructor(
		private actions$: Actions,
		private appointmentServiceApi: OnlineAppointmentService,
		private personServiceApi: PersonService
	) {
	}
}
