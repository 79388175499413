import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { WidgetFacade } from './+state/widget.facade';
import * as WidgetActions from './+state/widget.actions';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { FreeSlotsRequestDTO, FreeSlotsResponseDTO } from '@noventi/gp-platform/online-appointments';
import { PersonDTO, PublicTreatmentDTO } from '@noventi/gp-platform/care-providers';

@Injectable({
	providedIn: 'root'
})
export class WidgetService {

	private _profile: ReplaySubject<string> = new ReplaySubject<string>();

	constructor(
		private actionsSubject$: ActionsSubject,
		private widgetFacade: WidgetFacade
	) {
	}

	public set(value: string) {
		this._profile.next(value);
	}

	public get$(): Observable<string> {
		return this._profile.asObservable();
	}

	public provider$(token: string): Observable<PersonDTO> {
		this.widgetFacade.dispatch(WidgetActions.Provider({payload: token}));

		return this.actionsSubject$.pipe(
			ofType(WidgetActions.ProviderCompleted, WidgetActions.ProviderFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === WidgetActions.ProviderFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public treatments$(token: string): Observable<Array<PublicTreatmentDTO>> {
		this.widgetFacade.dispatch(WidgetActions.Treatments({payload: token}));

		return this.actionsSubject$.pipe(
			ofType(WidgetActions.TreatmentsCompleted, WidgetActions.TreatmentsFailed),
			switchMap((action) => {
				if (action.type === WidgetActions.TreatmentsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadSlots(freeSlotsRequest: FreeSlotsRequestDTO, findFirstAvailable: boolean = true): void {
		this.widgetFacade.dispatch(WidgetActions.Slots({
			payload: {freeSlotsRequest: freeSlotsRequest, findFirstAvailable: findFirstAvailable}
		}));
	}

	public getSlots$(): Observable<FreeSlotsResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(WidgetActions.SlotsCompleted, WidgetActions.SlotsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === WidgetActions.SlotsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}
}





