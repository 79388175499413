import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCOUNT_FEATURE_KEY, State, AccountPartialState } from './account.reducer';

// Lookup the 'Account' feature state managed by NgRx
export const getAccountState = createFeatureSelector<AccountPartialState, State>(ACCOUNT_FEATURE_KEY);


export const getData = createSelector(
	getAccountState,
	(state: State) => state.data
);

export const getDataEmail = createSelector(
	getAccountState,
	(state: State) => state.data ? state.data.email : undefined
);

export const getDataName = createSelector(
	getAccountState,
	(state: State) => state.data ? state.data.name : undefined
);

export const getManageEmployee = createSelector(
	getAccountState,
	(state: State) => state.manage && state.manage.employee ? state.manage.employee : undefined
);

export const getPermission = createSelector(
	getAccountState,
	(state: State) => state.permission
);
