import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import * as tinycolor from 'tinycolor2';
import { AppColor } from './app.interfaces';
import { WidgetService } from '@gp-angular/service/widget';

@Component({
	selector: 'noventi-widget-appointment',
	template: '<noventi-widget-interface></noventi-widget-interface>',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

	private readonly _profile: string;

	private static _computeColors(hex: string): AppColor[] {
		return [
			AppComponent._getColorObject(tinycolor(hex).lighten(52), '50'),
			AppComponent._getColorObject(tinycolor(hex).lighten(37), '100'),
			AppComponent._getColorObject(tinycolor(hex).lighten(26), '200'),
			AppComponent._getColorObject(tinycolor(hex).lighten(12), '300'),
			AppComponent._getColorObject(tinycolor(hex).lighten(6), '400'),
			AppComponent._getColorObject(tinycolor(hex), '500'),
			AppComponent._getColorObject(tinycolor(hex).darken(6), '600'),
			AppComponent._getColorObject(tinycolor(hex).darken(12), '700'),
			AppComponent._getColorObject(tinycolor(hex).darken(18), '800'),
			AppComponent._getColorObject(tinycolor(hex).darken(24), '900'),
			AppComponent._getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
			AppComponent._getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
			AppComponent._getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
			AppComponent._getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
		];
	}

	private static _getColorObject(value, name): AppColor {
		const c = tinycolor(value);
		return {
			name: name,
			hex: c.toHexString(),
			darkContrast: c.isLight()
		};
	}

	constructor(
		private widgetService: WidgetService,
		private changeDetectorRef: ChangeDetectorRef,
		private elementRef: ElementRef
	) {
		/** Read attr from widget and set */
		this._profile = this.elementRef.nativeElement.getAttribute('profile');
	}

	public ngOnInit(): void {
		registerLocaleData(localeDE);

		this.widgetService.set(this._profile);

		this._setColor('primary', this.elementRef.nativeElement.getAttribute('primaryColor'));
		this._setColor('secondary', this.elementRef.nativeElement.getAttribute('secondaryColor'));

		setTimeout(() => {
			const STYLE = document.createElement('style');
			const CSS = '.mat-calendar.range-selector {margin: 0 16px 8px 16em;}'
						+ '.mat-calendar.range-selector button:focus {background-color: initial;}'
						+ '.mat-calendar.range-selector .mat-calendar-table tr {border-bottom-width: 0;}'
						+ '.mat-calendar.range-selector .mat-calendar-header {padding-top: 0;}'
						+ '.mat-calendar.range-selector .mat-calendar-header .mat-calendar-period-button .mat-calendar-arrow {display: none;}'
						+ '.mat-calendar.range-selector .mat-calendar-header .mat-calendar-controls:after {content: \'\'; position: absolute; width: calc(100% - 16em - ((16px + 40px) * 2)); height: 40px; opacity: 0;}'
						+ '.mat-calendar.range-selector .mat-calendar-body-in-range::before {opacity: .2;}'
						+ '.mat-calendar.range-selector ~ .mat-datepicker-actions {display: flex; justify-content: space-between;}'
						+ '.mat-calendar.range-selector ~ .mat-datepicker-actions .aside {position: absolute; border-right: 1px solid #ccc; width: calc(16em - 24px); min-height: 20em; top: 16px; left: 8px;}'
						+ '.mat-calendar.range-selector ~ .mat-datepicker-actions .mat-button {background: inherit; margin-bottom: 8px;}'
						+ '.mat-calendar.range-selector ~ .mat-datepicker-actions .mat-button.title {margin-top: 8px;}'
						+ '.mat-calendar.range-selector ~ .mat-datepicker-actions .mat-button-base+.mat-button-base {margin-left: 0;}';
			STYLE.innerText = ':root {' + this.elementRef.nativeElement.getAttribute('style') + '}' + CSS;
			document.head.appendChild(STYLE)
		}, 100);
	}

	/** Set color palette */
	private _setColor(type: string, value: string) {
		const VALUE = !value ? type === 'primary' ? '#5A4EE6' : '#00D2A5' : value;
		AppComponent._computeColors(VALUE).forEach((color) => {
			const key1 = `--${type}-${color.name}`;
			const value1 = color.hex;
			const key2 = `--${type}-contrast-${color.name}`;
			const value2 = color.darkContrast ? 'rgba(black, 0.87)' : '#FFFFFF';
			this.elementRef.nativeElement.style.setProperty(key1, value1);
			this.elementRef.nativeElement.style.setProperty(key2, value2);
		});
	}
}
