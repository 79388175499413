import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PublicPersonDTO, PublicTreatmentDTO } from '@noventi/gp-platform/care-providers';
import { FreeSlotsRequestDTO, FreeSlotsResponseDTO } from '@noventi/gp-platform/online-appointments';

export const Provider = createAction(
	'[Widget] Provider',
	props<{ payload: string }>()
);

export const ProviderCompleted = createAction(
	'[Widget] Provider Completed',
	props<{ payload: PublicPersonDTO }>()
);

export const ProviderFailed = createAction(
	'[Widget] Provider Failed',
	props<{ error: HttpErrorResponse }>()
);

export const Treatments = createAction(
	'[Widget] Treatment List',
	props<{ payload: string }>()
);

export const TreatmentsCompleted = createAction(
	'[Widget] Treatments Completed',
	props<{ payload: Array<PublicTreatmentDTO> }>()
);

export const TreatmentsFailed = createAction(
	'[Widget] Treatments Failed',
	props<{ error: HttpErrorResponse }>()
);

export const Slots = createAction(
	'[Widget] Slots',
	props<{ payload: {freeSlotsRequest: FreeSlotsRequestDTO, findFirstAvailable: boolean} }>()
);

export const SlotsCompleted = createAction(
	'[Widget] Slots Completed',
	props<{ payload: FreeSlotsResponseDTO }>()
);

export const SlotsFailed = createAction(
	'[Widget] Slots Failed',
	props<{ error: HttpErrorResponse }>()
);
