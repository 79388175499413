import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import * as fromUser from './user.reducer';
import * as UserSelectors from './user.selectors';

@Injectable()
export class UserFacade {
	expires$ = this.store.pipe(select(UserSelectors.getExpires));
	passwordExpirationDate$ = this.store.pipe(select(UserSelectors.getPasswordExpirationDate));
	username$ = this.store.pipe(select(UserSelectors.getUsername));
	permissions$ = this.store.pipe(select(UserSelectors.getPermissions));
	uan$ = this.store.pipe(select(UserSelectors.getUan));
	loading$ = this.store.pipe(select(UserSelectors.getLoading));
	error$ = this.store.pipe(select(UserSelectors.getError));

	constructor(private store: Store<fromUser.UserPartialState>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
