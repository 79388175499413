import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { WidgetEffects } from './+state/widget.effects';
import { WidgetFacade } from './+state/widget.facade';
import { WidgetService } from './widget.service';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([WidgetEffects]),
	],
	providers: [WidgetFacade]
})
export class ServiceWidgetModule {
	static forRoot(): ModuleWithProviders<ServiceWidgetModule> {
		return {
			ngModule: ServiceWidgetModule,
			providers: [WidgetService]
		};
	}
}
