import { EnvironmentSchema, EnvironmentUpdateEnum } from '@gp-angular/shared/environment';

export class EnvironmentUtils {
	public static init(appConfig: EnvironmentSchema): void {
		if (typeof(Storage) !== 'undefined') {
			const app = JSON.parse(window.localStorage.getItem('application'));
			const user = JSON.parse(window.localStorage.getItem('user'));

			if (app) {
				if (app.versionUpdate || (appConfig && app.version !== appConfig.version)) {
					/** If MAJOR or MINOR update do below, ignored only on PATCH */
					if (app.versionUpdate !== 2) {
						window.localStorage.clear();
						window.localStorage.setItem('user', JSON.stringify(user));
					}
					app.version = appConfig.version;
					app.versionUpdate = undefined;
					window.localStorage.setItem('application', JSON.stringify(app));
					setTimeout(() => {
						window.location.reload();
					},1);
				}
			}
		}
	}

	public static getVersionUpdate(config: EnvironmentSchema, version: string): EnvironmentUpdateEnum {
		const localVersion: Array<string> = version.split('.');
		const fileVersion: Array<string> = config.version.split('.');
		const isMajor = localVersion[0] !== fileVersion[0];
		const isMinor = localVersion[1] !== fileVersion[1];
		const isPatch = localVersion[2] !== fileVersion[2];

		if (config.version !== version) {
			if (isMajor) {
				return EnvironmentUpdateEnum.MAJOR;
			} else if (isMinor) {
				return EnvironmentUpdateEnum.MINOR;
			} else if (isPatch) {
				return EnvironmentUpdateEnum.PATH;
			}
		}
	}

	public static getWidgetEnvironmentDomain(): string {
		const file = '/noventi-widget-appointment.js';
		const script = document.getElementById('noventi-widget-appointment');
		return script ? script.getAttribute("src").replace(file, '') : '.';
	}

	public static getRawAppVersion(): string {
		const APP = JSON.parse(window.localStorage.getItem('application'));

		return !!APP ? APP.version : Date.now();
	}
}
