import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, timer } from 'rxjs';
import { retryWhen, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentUtils } from '@gp-angular/shared/utils';
import { EnvironmentSchema } from '@gp-angular/shared/environment';

@Injectable({
	providedIn: 'any'
})
export class EnvironmentService {

	public static config: EnvironmentSchema;

	private environment$: Observable<EnvironmentSchema>;

	private readonly CONFIG_URL = EnvironmentUtils.getWidgetEnvironmentDomain() + '/assets/config/config.json';

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private http: HttpClient
	) {
	}

	public getEnvironment(): any {
		this.check$().subscribe((next) => this._environment = next);
		return this._environment;
	}

	public fcKey(): string {
		// TODO: Review later. Case on development mode: reload, config is undefined
		return EnvironmentService.config ? EnvironmentService.config.fcKey : 'CC-Attribution-NonCommercial-NoDerivatives';
	}

	public check$(): Observable<EnvironmentSchema> {
		const headers = new HttpHeaders()
			.set('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate, post- check=0, pre-check=0')
		this.environment$ = this.http.get<EnvironmentSchema>(this.CONFIG_URL, {headers: headers, params: {ignore: 'true'}}).pipe(
			tap((response: EnvironmentSchema) => {
				EnvironmentService.config = response;

				this._environment.production = response.production;
				this._environment.name = response.name;
				this._environment.version = response.version;
				this._environment.endpoints = {...response.endpoints};
				if (!!response.websocket) {
					this._environment.websocket = {...response.websocket};
				}
				this._environment.analytics = {...response.analytics};

			}),
			retryWhen(() => EMPTY),
			shareReplay(1)
		);
		return this.environment$;
	}

	/**
	 * Interval of checking new version is 10min (1000 * 60 * 10)
	 */
	public interval$(): Observable<EnvironmentSchema> {
		return timer(0, 1000 * 60 * 10).pipe(
			switchMap( () => {
				return this.check$();
			})
		);
	}
}
