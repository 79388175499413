import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	USER_FEATURE_KEY,
	State,
	UserPartialState
} from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
export const getUserState = createFeatureSelector<UserPartialState, State>(
	USER_FEATURE_KEY
);

export const getExpires = createSelector(
	getUserState,
	(state: State) => state.expires
);

export const getPasswordExpirationDate = createSelector(
	getUserState,
	(state: State) => state.ped
);

export const getUsername = createSelector(
	getUserState,
	(state: State) => state.username
);

export const getPermissions = createSelector(
	getUserState,
	(state: State) => state.permissions
);

export const getUan = createSelector(
	getUserState,
	(state: State) => state.uan
);

export const getLoading = createSelector(
	getUserState,
	(state: State) => state.loading
);

export const getError = createSelector(
	getUserState,
	(state: State) => state.error
);

